<template>
	<div class="">
		<AppCard class="create-post-card-container">
			<div ref="createPostElement" class="create-post-container relative" @dragover.prevent="handleDragOver">
				<transition name="slide">
					<MessageForm
						class="message-form"
						:key="currentChannel.channel_id"
						:disable="isInactiveChannel"
						@submit="sendMessage"
						ref="messageForm"
						:visible="toggled"
						:placeholder="placeholder"
						:focus="$route.params.threadId || false"
						@messageUpdated="messageUpdated"
						@blur="blurEditor"
						@focus="focusEditor"
						:loaded-files="preLoadedFiles"
						:loading="loading"
					/>
				</transition>
			</div>
		</AppCard>
	</div>
</template>
<script>
	import debounce from '@/plugins/debounce'
	import { useCommunityStore } from '@/stores/communities'
	import { useElementsStore } from '@/stores/elements'
	import AppIcon from '../shared/AppIcon.vue'
	import PodcastModal from './PodcastModal.vue'

	export default {
		name: 'CreatePost',
		data() {
			return {
				toggled: false,
				placeholder: 'Write something...',
				// Dummy data
				currentChannel: { channel_id: 1 },
				isInactiveChannel: false,
				messageDraft: '',
				preLoadedFiles: [],
				loading: false,
			}
		},
		methods: {
			messageUpdated(message) {
				// Catch to ensure message is visible when typing
				if (message.content && message.content !== this.$constants.TIPTAP_EMPTY_MESSAGE && !this.toggled) {
					this.toggled = true
				}
			},
			sendMessage(content, tag_id) {
				this.loading = true
				try {
					this.communityStore.sendMessage(content, tag_id, null)
					localStorage.setItem(`draft-${this.currentChannel.channel_id}`, null)
					this.toggled = false
					this.$emit('scroll-to-top')
					this.$emit('set-tag', tag_id)
				} catch (e) {
					alert('Failed to send message')
				}
				this.loading = false
			},
			handleDragOver() {
				this.toggled = true
			},
			focus() {
				this.$refs.messageForm.focusEditor()
			},
			showPodcast() {
				console.log('here')
				this.$refs.podcastModal.show()
			},
		},
		props: {},
		computed: {
			communityStore() {
				return useCommunityStore()
			},
			elementsStore() {
				return useElementsStore()
			},
			user() {
				const user = this.communityStore.self?.data
				return {
					name: user?.full_name,
					src: user?.profile_image,
				}
			},
			blurEditor() {
				return this.$debounce(() => {
					this.toggled = false
				}, 60)
			},
			focusEditor() {
				return this.$debounce(() => {
					this.toggled = true
				}, 60)
			},
		},
		watch: {
			toggled() {
				this.$emit('editor-toggled')
			},
		},
		mounted() {
			this.elementsStore.setCreatePostElement(this.$refs.createPostElement)
		},
	}
</script>

<style lang="scss" scoped>
	.create-post-container {
		max-width: 1024px;
		@apply w-full box-border;

		@media (max-width: 1023px) {
			background-color: var(--stan-gray-soft-color, #f2f4f8);
			@apply fixed bottom-0 left-0 z-40 rounded-t-xl p-4;
		}
		.message-form {
			@apply w-full box-border;
		}
	}

	.create-post-card-container.app-card-container {
		overflow: unset;
	}
</style>
