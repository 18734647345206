<template>
	<div v-if="hasPodcastEnabled" class="bg-white rounded-2xl p-4 relative">
		<div class="pb-6 pt-3 flex flex-row justify-between">
			<h3 class="text-bold flex items-center gap-1">Follow Along</h3>
		</div>
		<div
			class="shadow-stan-box-shadow relative overflow-visible rounded-xl bg-white mb-4 hover:cursor-pointer p-4 px-3 gap-3;"
			@click="connectApplePodcast"
		>
			<div class="flex flex-row w-full justify-between rounded-xl text-stan-text-dark text-sm">
				<div class="flex flex-row gap-2 w-full items-center">
					<img
						src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Podcasts_%28iOS%29.svg/300px-Podcasts_%28iOS%29.svg.png?20211002125540"
					/>
					<div class="overflow-hidden">
						<div class="helpful-webinar-title para-2 text-bold">Apple Podcast</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup>
	import { ref, computed } from 'vue'
	const hasPodcastEnabled = computed(() => true)
	const communityStore = useCommunityStore()
	const connectApplePodcast = () => {
		window.open(`${podcastURL.value}v1/rss/${communityStore.currentCommunity.slug}/${communityStore.obfuscatedId}`, '_blank')
	}
	const podcastURL = computed(() => configSettings.communityApiUrl.replace(/^https?:\/\//, 'podcast://'))
</script>
<style lang="scss" scoped>
	img {
		height: 2.5rem;
		width: 2.5rem;
		border-radius: 9999px;
	}
</style>
