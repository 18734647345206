<template>
	<div v-if="data.length || canModifyChannels" class="bg-white rounded-2xl p-4 relative">
		<div class="pb-6 pt-3 flex flex-row justify-between">
			<h3 class="text-bold flex items-center gap-1">Helpful Links</h3>
			<div v-if="canModifyChannels">
				<AppButton link icon="plus" size="md" color="light" @click="showEditor()"> Add</AppButton>
			</div>
		</div>

		<div v-if="reorderingData.length" class="bg-white flex flex-col rounded-xl text-stan-text-dark text-sm">
			<VueDraggable v-model="reorderingData" @update="updateOrder" scroll v-if="canModifyChannels">
				<TransitionGroup type="transition" name="fade">
					<LinkComponent
						class="helpful-link-wrapper"
						:index="index"
						:link="link"
						v-for="(link, index) in reorderingData"
						:key="`${link.url}-${index}-${link.title}-${link.image}`"
					/>
				</TransitionGroup>
			</VueDraggable>
			<template v-else>
				<LinkComponent
					class="helpful-link-wrapper"
					:index="index"
					:link="link"
					v-for="(link, index) in reorderingData"
					:key="`${link.url}-${index}-${link.title}-${link.image}`"
				/>
			</template>
		</div>
		<div
			v-else-if="canModifyChannels"
			@click="showEditor"
			class="bg-white p-5 flex flex-col border-dotted border-2 border-stan-gray rounded-xl text-stan-text-light font-light text-sm items-center justify-center text-center hover:cursor-pointer select-none"
		>
			Add useful links for your community
		</div>
	</div>
	<AddLinksModal ref="addLinksModal" :closeOnBlur="false" />
</template>

<script setup>
	import { ref, onMounted, computed, watch } from 'vue'
	import axios from 'axios'
	import { useCommunityStore } from '@/stores/communities'
	import LinkComponent from './LinkComponent.vue'
	import { VueDraggable } from 'vue-draggable-plus'

	const communityStore = useCommunityStore()
	const canModifyChannels = computed(() => communityStore.canModifyChannels)

	const data = computed(() => communityStore.currentCommunity?.data?.links || [])
	const reorderingData = ref([])
	const addLinksModal = ref(null)

	async function deleteLink(link) {
		const dataLinks = data.value
		const linkIndex = dataLinks.findIndex(dataLink => dataLink.title === link.title && dataLink.url === link.url && dataLink.url === link.url)
		dataLinks.splice(linkIndex, 1)
		await communityStore.updateCommunityData({
			links: dataLinks,
		})
	}
	const showEditor = () => {
		if (canModifyChannels.value) addLinksModal.value.show()
	}

	const updateOrder = async () => {
		await communityStore.updateCommunityData({
			links: reorderingData.value,
		})
	}

	watch(
		() => data.value,
		() => {
			reorderingData.value = data.value
		},
		{
			immediate: true,
		}
	)
</script>

<style lang="scss" scoped>
	.helpful-link- {
		&wrapper {
		}
		&image {
			@apply rounded-full w-10 h-10;
		}
		&text-wrapper {
			@apply gap-0.5 flex flex-col;
		}
		&title {
			text-transform: capitalize;
		}
		&url {
			@apply text-stan-gray-dark;
		}
		&trash-button.app-button.link {
			@apply ml-auto mr-1 p-2;
			&:hover {
				background-color: var(--stan-gray-light-color) !important;
			}
		}
	}
	.posts-list-container {
		@apply w-full;
		.empty-post {
			@apply box-border border-dashed rounded-xl border-stan-gray-dark border;
			@apply w-full p-24 flex flex-col justify-center items-center;
			@apply text-stan-text-light text-base -tracking-32 leading-6;
		}
	}
	.buttons-wrapper {
		@apply flex flex-row gap-1;
	}
	.fade-move,
	.fade-enter-active,
	.fade-leave-active {
		transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
		transform: scaleY(0.01) translate(30px, 0);
	}

	.fade-leave-active {
		position: absolute;
	}
</style>
