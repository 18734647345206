<template>
	<div class="full-comment-section-container">
		<span class="text-light p-4 para-2 text-bold">{{ totalCommentsText }} </span>

		<div id="comment-section" class="comments-container" ref="commentsContainer">
			<SolidDotsSpinner v-show="loading && hasComments" :dot-size="10" color="var(--community-primary-color)" />
			<Comment
				:id="comment.message_id"
				v-show="!loading"
				:message-id="comment.message_id"
				:parent-message-id="comment.parent_message_id"
				v-for="(comment, index) in comments"
				:key="index"
				@comments-loaded="loading = false"
				isEditable
				isFullComment
			/>
		</div>

		<div class="reply-container">
			<CommentReply :shouldScroll="true" :messageId="messageId" />
		</div>
	</div>
</template>
<script setup>
	import { isEmpty } from 'lodash'
	import { defineProps, computed, ref, getCurrentInstance, onMounted, watchEffect, watch, nextTick } from 'vue'
	import { useCommunityStore } from '@/stores/communities'

	/**
	 * Instance
	 */
	const { appContext } = getCurrentInstance()

	/**
	 * Props
	 */
	const props = defineProps({
		messageId: {
			type: Number,
			description: 'Id of the message to respond too',
		},
		commentId: {
			type: Number,
			description: 'Id of the comment clicked on from notifications to scroll to',
		},
	})

	/**
	 * Computed
	 */
	const communityStore = computed(() => useCommunityStore())
	const comments = computed(() => communityStore.value.currentChannelThreads[props.messageId]?.messages || {})
	const hasComments = computed(() => communityStore.value.messages[props.messageId]?.thread_count > 0)
	const totalComments = computed(() => calculateAmountOfComments())
	const totalCommentsText = computed(() => {
		if (totalComments.value === 1) return '1 Comment'
		return `${totalComments.value} Comments`
	})
	/**
	 * Refs & Data
	 */
	const commentsContainer = ref(null)
	// Comments only load when there are none or we think there are none
	const loading = ref(isEmpty(comments))
	const threadOldestMessageId = computed(() => {
		const message_identifiers = Object.keys(comments.value)
		return message_identifiers.length
			? comments.value[message_identifiers.map(messageId => parseInt(messageId)).sort((a, b) => a - b)[0]].message_id
			: null
	})
	const calculateAmountOfComments = () => {
		const commentsArray = Object.values(comments.value ?? {})
		return commentsArray.length + commentsArray.reduce((acc, curr) => acc + curr.thread_count, 0)
	}

	onMounted(async () => {
		if (props.commentId) {
			await nextTick() // Ensure DOM updates
			const commentSection = document.getElementById('comment-section')
			const targetComment = commentSection.querySelector(`[id='${props.commentId}']`)
			const commentContent = targetComment?.querySelector(`[id='comment-content-${props.commentId}']`)
			if (targetComment && commentSection) {
				targetComment.scrollIntoView({
					behavior: 'smooth',
					block: 'end',
				})
				if (commentContent) {
					commentContent.classList.add('border-2', 'border-solid', 'border-community-primary')
					setTimeout(() => {
						commentContent.classList.add('border-white', 'transition-all')
					}, 3000)
				}
			}
		}
	})
</script>
<style lang="scss" scoped>
	.full-comment-section-container {
		@apply lg:h-full lg:overflow-hidden flex flex-col  box-border;
		@apply lg:max-w-lg bg-stan-gray-soft pb-6 flex flex-col lg:max-h-full;

		.comments-container {
			overflow-y: scroll;
			overflow: auto;
			@apply flex flex-col gap-4 overflow-auto  mt-auto p-4 sm:p-6 pb-12;
			.comment-container {
				@apply w-full;
			}
		}

		.reply-container {
			@apply pt-4 mx-6;
			@media (max-width: 1023px) {
				background-color: var(--stan-gray-soft-color, #f2f4f8);
				@apply fixed bottom-0 left-0 z-40 rounded-t-xl p-4 mx-0 w-full;
			}
		}
	}
</style>
