<template>
	<AppModal
		ref="manageCategoriesModal"
		title="Manage Categories"
		action="Add Category"
		@cta="showAddChannelModal"
		@close="close"
		class="manage-categories-modal"
	>
		<div class="pb-8 flex flex-col gap-4 content-container">
			<div v-if="!tagNames.length" class="empty-post !p-12 sm:!p-24">
				You have no active categories yet. To begin categorizing your posts, click below!
			</div>
			<div
				v-for="tag in tags"
				v-else
				:key="tag.tag_id"
				class="flex flex-row gap-y-5 justify-between items-center bg-white h-20 w-full pl-5 pr-3 py-4 md:py-2 category-card"
			>
				<div class="flex items-center w-full -ml-2.5 mr-4" v-if="isEditing[tag.tag_id]">
					<AppInput
						class="w-full"
						inputClass="h-10 !min-h-10 !text-base"
						:hide-error-spacing="true"
						v-model="updatedName[tag.tag_id]"
						placeholder="Category Name"
						:maxlength="20"
						@input="() => (updatedNameError[tag.tag_id] = [])"
						:error="updatedNameError[tag.tag_id] || []"
					/>
				</div>
				<div class="flex items-center" v-else>
					{{ tag.name }}
				</div>
				<div class="ml-2 relative">
					<div class="flex flex-row gap-4" v-if="isEditing[tag.tag_id]">
						<SolidDotsSpinner v-if="loading[tag.tag_id]" :dot-size="9" color="var(--community-primary-color)" class="loader" />
						<template v-else>
							<button :class="['flex']" @click="() => updateName(tag.tag_id)">
								<AppIcon class="inline" src="check" />
							</button>
							<button :class="['flex']" @click="() => (isEditing[tag.tag_id] = false)">
								<AppIcon class="inline" src="close" />
							</button>
						</template>
					</div>

					<AppSelectDropdown
						v-else
						:menu="true"
						dropdown-menu-class="!min-w-max"
						:options="selectOptions"
						@value-selected="value => valueSelected(value, tag.tag_id, tag.name)"
					/>
				</div>
			</div>
		</div>
	</AppModal>
	<AddCategoryModal ref="addCategoryModal" key="addChannelModalId" @close="onAddChannelModalClose" @added-tag="handleAddTag" />
	<DeleteCategoryModal
		ref="deleteCategoryModal"
		:tag-id="deleteTagId"
		:tag-name="deleteTagName"
		key="deleteCategoryModalId"
		@close="onDeleteCategoryModalClose"
	/>
</template>

<script>
	import { useVuelidate } from '@vuelidate/core'
	import { required, helpers } from '@vuelidate/validators'
	import { useCommunityStore } from '@/stores/communities'

	export default {
		props: {
			backdrop: {
				type: Boolean,
				default: true,
			},
		},
		data() {
			return {
				updatedName: {},
				updatedNameError: {},
				isEditing: {},
				deleteTagId: null,
				deleteTagName: '',
				loading: {},
			}
		},
		unmounted() {
			this.reset()
		},
		computed: {
			communityStore() {
				return useCommunityStore()
			},
			tags() {
				return this.communityStore.tags
			},
			tagNames() {
				return Object.keys(this.tags).map(key => this.tags[key].name.toUpperCase())
			},
			validations() {
				return {
					title: {
						required: helpers.withMessage('Title is required', required),
						unique: helpers.withMessage('Category already exists', value => !this.tagNames.includes(value.toUpperCase())),
					},
				}
			},
			selectOptions() {
				return [
					{ value: 'edit', label: 'Edit', iconBefore: 'edit' },
					{ value: 'delete', label: 'Delete', iconBefore: 'trash' },
				]
			},
		},
		methods: {
			valueSelected(value, tagId, tagName) {
				switch (value) {
					case 'edit':
						this.enableEditMode(tagId, tagName)
						break
					case 'delete':
						this.showDeleteCategoryModal(tagId, tagName)
						break
				}
			},
			reset() {
				this.isEditing = {}
				this.loading = {}
			},
			show() {
				this.$refs.manageCategoriesModal.show()
			},
			showAddChannelModal() {
				this.$refs.addCategoryModal.show()
				this.close()
			},
			showDeleteCategoryModal(tag_id, name) {
				this.deleteTagId = tag_id
				this.deleteTagName = name
				this.$refs.deleteCategoryModal.show()
				this.close()
			},
			close() {
				this.$emit('close')
				this.$refs.manageCategoriesModal.hide()
				this.reset()
			},
			onAddChannelModalClose() {
				this.close()
			},
			handleAddTag(tag) {
				this.$emit('set-tag', tag)
			},
			onDeleteCategoryModalClose() {
				this.show()
			},
			enableEditMode(tag_id, name) {
				this.isEditing[tag_id] = true
				this.updatedName[tag_id] = name
				this.updatedNameError[tag_id] = []
			},
			async updateName(tag_id) {
				this.updatedNameError[tag_id] = []
				const name = this.updatedName[tag_id]
				// validation
				// no change made
				if (name === this.tags[tag_id].name) {
					this.isEditing[tag_id] = false
					return
				}
				const v$ = useVuelidate(this.validations, { title: name })
				v$.value.$touch()
				if (v$.value.title.$error) {
					this.updatedNameError[tag_id] = v$.value.title.$errors
					return
				}
				// validation end
				this.loading[tag_id] = true
				this.$stanAnalytics('community-category-update', {
					props: { categoryId: tag_id },
				})
				await this.communityStore.updateTagName(tag_id, name)
				this.loading[tag_id] = false
				this.isEditing[tag_id] = false
			},
		},
	}
</script>

<style lang="scss" scoped>
	// reset modal padding to show box shadow of category-cards
	.manage-categories-modal {
		:deep(.modal.padding) {
			padding: 0 !important;
		}

		:deep(.header) {
			margin-bottom: 1rem !important;
			@apply p-8 pb-0;
		}

		:deep(.actions) {
			@apply p-8 pt-0;
		}

		:deep(.content) {
			max-height: 65vh;
			min-height: 28vh;
			@apply overflow-auto;
		}

		:deep(.content .content-container) {
			@apply px-8 py-4;
		}

		:deep(.content .category-card .input-message) {
			@apply absolute;
		}
	}

	.category-card {
		@apply shadow-stan-box-shadow rounded-lg;
	}
</style>
