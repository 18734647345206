<template>
	<Teleport to="body">
		<AppModal ref="podcastModal" title="Edit Link" action="Publish" :loading="loading" :backdrop="backdrop" @cta="publish" @close="close">
			<AppInput class="mb-2" v-model="title" label="Title" placeholder="Name your episode here" :error="v$.title.$errors" :maxlength="50" />
			<AppTextArea
				class="mb-0 text-area"
				v-model="summary"
				label="Summary"
				placeholder="Add a summary for your podcast episode"
				:error="v$.summary.$errors"
			/>
			<div class="para-3 text-bold mt-3">Description</div>
			<TipTapEditor
				v-model:value="description"
				hasToolbar
				:should-show-image-picker="false"
				:should-show-file-picker="canUploadFile"
				:shouldShowVideoPicker="false"
				placeholder="Add a description for your episode"
				editor-wrapper-class="content-wrapper pb-36 bg-stan-gray-light p-2"
				toolbar-class="!h-10 !px-2 !mt-2"
				@pick-file="pickFile"
			></TipTapEditor>
			<div class="flex flex-row flex-wrap gap-5">
				<div
					:key="index"
					v-for="(file, index) in files"
					class="attachment"
					:class="[digitalAssetType(file), { fileUploading: file.progress !== undefined, fileUploaded: file.progress === undefined }]"
				>
					<div class="w-full h-full overflow-hidden">
						<AppImage
							class="rounded img cursor-pointer"
							cover
							:previewImage="true"
							:src="file.url"
							:alt="file.name"
							v-if="digitalAssetType(file) === 'image'"
						/>
						<AppIcon src="arc" class="loading-icon animate-spin" />

						<div class="img" v-if="digitalAssetType(file) === 'file'">
							<AppIcon class="lg" src="file" />
						</div>
						<div class="img" v-if="digitalAssetType(file) === 'video'">
							<AppIcon class="sm" src="play" />
						</div>
					</div>
					<div class="file-details" v-if="digitalAssetType(file) === 'file' || digitalAssetType(file) === 'video'">
						<div class="file-name">
							{{ file.name }}
						</div>
						<div class="file-type">
							{{ digitalAssetTypeName(file.type) }}
						</div>
					</div>
					<div class="action-item d-none" @click="removeFile(file.id)">
						<AppIcon src="close" />
					</div>
				</div>
			</div>
			<AppFileUpload
				key="unsplashImage"
				ref="podcastMedia"
				:accepts="inputAccepts"
				@assetUp="fileUploaded"
				@filePicked="filePicked"
				@progress="progressUploaded"
				@errorUp="errorFileUploaded"
			/>
			<!-- <div>
				<div class="para-3 text-bold mt-3">Video</div>
				<div
					class="flex items-center justify-center h-40 bg-stan-gray-light border-2 border-dashed border-stan-gray-dark rounded-md mt-2 hover:cursor-pointer"
					@click="$refs.podcastMedia.onPickFile"
				>
					Choose your video file
				</div>
			</div>
			<div>
				<div class="para-3 text-bold mt-3">Audio</div>
				<div
					class="flex items-center justify-center h-40 bg-stan-gray-light border-2 border-dashed border-stan-gray-dark rounded-md mt-2 hover:cursor-pointer"
					@click="$refs.podcastMedia.onPickFile"
				>
					Choose your audio file
				</div>
			</div> -->
		</AppModal>
	</Teleport>
</template>

<script>
	import { useVuelidate } from '@vuelidate/core'
	import { required, helpers, url, maxLength } from '@vuelidate/validators'
	import { useCommunityStore } from '@/stores/communities'
	import AppTextArea from '../shared/AppTextArea.vue'
	import TipTapEditor from '../shared/TipTapEditor.vue'
	import AppFileUpload from '../shared/AppFileUpload.vue'
	import { digitalAssetType, digitalAssetTypeName } from '../shared/utils'

	export default {
		setup: () => ({ v$: useVuelidate() }),
		props: {
			isEditing: {
				type: Boolean,
				default: false,
			},
			backdrop: {
				type: Boolean,
				default: true,
			},
			linkIndex: {
				type: Number,
			},
			link: {
				type: Object,
			},
		},
		data() {
			return {
				title: '',
				url: '',
				image: '',
				loading: false,
				summary: '',
				description: null,
				files: [],
			}
		},
		computed: {
			communityStore() {
				return useCommunityStore()
			},
			showing() {
				return this.$refs.podcastModal.showing
			},
			hasVideoUploaded() {
				return this.files.map(file => this.digitalAssetType(file)).includes('video')
			},
			hasAudioUploaded() {
				return this.files.map(file => this.digitalAssetType(file)).includes('file')
			},
			inputAccepts() {
				const acceptsArray = []
				if (!this.hasVideoUploaded) {
					acceptsArray.push('video/*')
				}
				if (!this.hasAudioUploaded) {
					acceptsArray.push('audio/*')
				}
				return acceptsArray.join(',')
			},
			canUploadFile() {
				return !(this.hasVideoUploaded && this.hasAudioUploaded)
			},
		},
		validations() {
			return {
				title: {
					required: helpers.withMessage('Title is required', required),
				},
				summary: {
					required: helpers.withMessage('Summary is required', required),
				},
			}
		},
		methods: {
			digitalAssetType: digitalAssetType,
			digitalAssetTypeName: digitalAssetTypeName,
			publish() {
				this.loading = true
				this.communityStore.sendMessage(
					{
						content: this.description,
						files: this.files,
						audio: [],
						linkPreviews: [],
						title: this.title,
						summary: this.summary,
						type: 'podcast',
						mentions: [],
						notify: this.$constants.MESSAGE_NOTIFY.DEFAULT,
					},
					null
				)
				this.loading = false
				this.close()
			},
			removeFile(fileId) {
				const fileIndex = this.files.findIndex(f => f.id === fileId)
				if (this.files[fileIndex].progress !== undefined) {
					// TODO: Stop file from uploading
					this.files.splice(fileIndex, 1)
				} else {
					this.files.splice(fileIndex, 1)
				}
			},
			pickFile() {
				this.$refs.podcastMedia.onPickFile()
			},
			fileUploaded(e) {
				const fileIndex = this.files.findIndex(f => f.id === e.metadata.id)
				if (fileIndex !== -1) {
					this.files[fileIndex] = {
						url: e.src,
						name: e.name,
						id: e.metadata.id,
						type: e.type,
					}
				}
				console.log(this.files)
			},
			errorFileUploaded(e) {
				this.$notify({ type: 'error', text: e.error })
				if (e.metadata?.id) {
					this.files.splice(
						this.files.indexOf(f => f.id === e.metadata?.id),
						1
					)
				}
			},
			filePicked(files) {
				for (const file in files) {
					this.files.push({
						name: files[file].name,
						type: files[file].type,
						id: files[file].metadata.id,
						progress: 0,
					})
				}
			},
			progressUploaded(file) {
				this.files.forEach(f => {
					if (f.id === file.metadata.id) {
						f.progress = file.progress
					}
				})
			},

			show() {
				console.log(this.description)
				this.v$.$reset()
				this.$refs.podcastModal.show()
			},
			close() {
				this.$emit('close')
				this.$refs.podcastModal.hide()
			},
		},
	}
</script>

<style lang="scss" scoped>
	.invite-user-modal {
		:deep(.modal-inner-title) {
			overflow: visible;
		}
	}
	.profile-sub-secion-right-image {
		max-width: 100px;
		.profile-photo-wrapper {
			position: relative;
			.abs-add-image {
				position: absolute;
				right: 20px;
				bottom: -8px;
				display: inline-block;
				img {
					width: 20px;
				}
			}
		}
	}
	.attachment {
		@apply w-14 h-14 aspect-square relative bg-white border-stan-gray-strike border-2 rounded-xl mt-3;

		.img {
			@apply flex items-center;

			:deep(svg) {
				@apply bg-community-light w-8 h-8 aspect-square rounded p-1;

				rect,
				path {
					@apply fill-community-primary-d-text-color;
				}
			}
		}

		&.audio {
			@apply w-72 border-none;
			:deep(.audio-player-card) {
				@apply justify-between w-full;
			}
		}

		.loading-icon {
			@apply w-full h-full;
		}

		&.fileUploading {
			.img {
				@apply hidden;
			}

			.loading-icon {
				@apply block;
			}
		}

		&.fileUploaded {
			@apply h-full;
			.img {
				@apply block;
			}

			.loading-icon {
				@apply hidden;
			}
		}

		.action-item {
			@apply absolute -right-2 -top-2 rounded-full bg-stan-gray-primary;

			svg {
				@apply w-5 h-5 p-0.5;
			}
		}

		&.image {
			@apply bg-stan-gray-strike;

			:deep(.img) {
				@apply w-full h-full rounded-xl overflow-hidden;

				img {
					@apply border-0 bg-transparent;
				}
			}
		}

		&.file,
		&.video {
			@apply w-auto aspect-auto max-w-52 flex gap-3 p-2;

			.file-details {
				@apply flex flex-col select-none;

				.file-name {
					@apply truncate w-36 font-semibold;
				}

				.file-type {
					@apply text-stan-text-light font-light;
				}
			}
		}
	}
</style>
