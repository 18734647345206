<template>
	<div v-if="selectedWebinars.length > 0 || isOwner" class="bg-white rounded-2xl p-4 relative">
		<div class="pb-6 pt-3 flex flex-row justify-between">
			<h3 class="text-bold flex items-center gap-1">Upcoming Webinars</h3>
			<div v-if="isOwner" class="relative">
				<div ref="dropDownButtonRef">
					<AppButton link icon="plus" size="md" color="light" @click="toggleEditDropdown"> {{ buttonTitleText }}</AppButton>
				</div>
				<div
					v-if="isDropdownOpen"
					class="absolute right-0 mt-2 flex flex-col gap-2 bg-white border border-gray-200 rounded-xl shadow-lg p-4 z-[1000] w-[320px] max-h-64 overflow-y-scroll"
					ref="dropdownRef"
				>
					<div class="text-gray-400 px-4 mb-3">Select webinars to display</div>
					<div
						v-if="webinars.length > 0"
						v-for="(webinar, index) in webinars"
						:key="index"
						class="flex items-center w-full px-4 gap-2 justify-between hover:bg-stan-gray-soft rounded-lg p-2"
					>
						<div class="flex flex-row justify-between w-full">
							<div class="flex items-center space-x-1">
								<label :for="'webinar-checkbox-' + index" class="flex items-center cursor-pointer gap-2">
									<input
										class="sr-only peer"
										type="checkbox"
										:id="'webinar-checkbox-' + index"
										v-model="selectedWebinars"
										:value="webinar.data.product.id"
										@change="applyFilter"
									/>
									<span
										class="cursor-pointer flex-shrink-0 flex items-center justify-center h-5 w-5 border peer-checked:border-community-primary rounded-md peer-checked:bg-community-primary transition-colors"
									>
										<AppIcon class="color-text-white sm" src="check" />
									</span>
									<span class="flex items-center gap-1">
										<img :src="webinar.data.product.image" class="w-6 h-6" />
										<div class="overflow-hidden font-s text-md ml-3">
											{{ webinar.data.product.title }}
										</div>
									</span>
								</label>
							</div>
						</div>
					</div>
					<div
						class="bg-white p-5 flex flex-col border-dotted border-2 border-stan-gray rounded-xl text-stan-text-light font-light text-sm items-center justify-center text-center"
						v-else
					>
						Create webinar products to add them easily in your community!
					</div>
					<AppButton icon="plus" @click="addWebinar">Create New Webinar</AppButton>
				</div>
			</div>
		</div>

		<div v-for="(webinar, index) in webinarsToShow" :key="index">
			<WebinarComponent :webinar="webinar" />
		</div>
		<div
			v-if="selectedWebinars.length === 0"
			class="bg-white p-5 flex flex-col border-dotted border-2 border-stan-gray rounded-xl text-stan-text-light font-light text-sm items-center justify-center text-center select-none"
		>
			Share existing webinars with your community
		</div>
	</div>
</template>

<script setup>
	import { ref, computed, watch, onMounted, onUnmounted, inject } from 'vue'
	import WebinarComponent from './WebinarComponent.vue'
	import { useCommunityStore } from '@/stores/communities'
	import AppButton from '../shared/AppButton.vue'
	import { configSettings } from '@/plugins/configSettings'
	import { useCommunityPublicStore } from '@/stores/public'
	import { useProductsStore } from '@/stores/products'

	const stanAnalytics = inject('stanAnalytics')
	const communityStore = useCommunityStore()
	const communityPublicStore = useCommunityPublicStore()
	const productsStore = useProductsStore()
	const isOwner = computed(() => communityStore.isOwner)
	const buttonTitleText = computed(() => (webinars.value.length === 0 ? 'Create' : 'Add'))
	const webinars = computed(() => {
		const now = new Date()
		return Array.from(productsStore.webinars)
			.map(webinar => {
				const upcomingSessions = webinar.data.product.webinar.sessions.filter(session => {
					return session.datetime >= now
				})
				return {
					...webinar,
					data: {
						...webinar.data,
						product: {
							...webinar.data.product,
							webinar: {
								...webinar.data.product.webinar,
								sessions: upcomingSessions,
							},
						},
					},
				}
			})
			.filter(webinar => webinar)
	})
	const isDropdownOpen = ref(false)
	const dropdownRef = ref(null)
	const dropDownButtonRef = ref(null)
	const selectedWebinars = ref([])
	const selectedWebinarsInData = computed(() => communityStore.currentCommunity?.data?.selectedWebinars || [])
	const webinarsToShow = computed(() => {
		const webinars = Array.isArray(productsStore?.webinars) ? productsStore.webinars : []
		const selected = Array.isArray(selectedWebinars.value) ? selectedWebinars.value : []

		return webinars.filter(webinar => selected.includes(webinar.data.product.id))
	})
	if (selectedWebinarsInData.value.length > 0) {
		selectedWebinars.value = selectedWebinarsInData.value
	}
	watch(selectedWebinarsInData, newValue => {
		if (newValue !== undefined) {
			selectedWebinars.value = newValue
		}
	})

	const toggleEditDropdown = () => {
		isDropdownOpen.value = !isDropdownOpen.value
	}

	const applyFilter = async () => {
		communityStore.updateCommunityData({
			selectedWebinars: selectedWebinars.value,
		})
		isDropdownOpen.value = false
	}
	const onClickOutside = event => {
		if (
			dropdownRef.value &&
			!dropdownRef.value.contains(event.target) &&
			dropDownButtonRef.value &&
			!dropDownButtonRef.value.contains(event.target)
		) {
			isDropdownOpen.value = false
		}
	}
	const addWebinar = () => {
		stanAnalytics('community-new-webinar')
		window.open(`${configSettings.adminDomain}/stores/${communityPublicStore.store_id}/page/webinar/create?location=landing`, 'blank')
	}
	onMounted(() => {
		document.addEventListener('mousedown', onClickOutside)
	})

	onUnmounted(() => {
		document.removeEventListener('mousedown', onClickOutside)
	})
</script>

<style lang="scss" scoped>
	.helpful-link- {
		&wrapper {
		}
		&image {
			@apply rounded-full w-10 h-10;
		}
		&text-wrapper {
			@apply gap-0.5 flex flex-col;
		}
		&title {
			text-transform: capitalize;
		}
		&url {
			@apply text-stan-gray-dark;
		}
		&trash-button.app-button.link {
			@apply ml-auto mr-1 p-2;
			&:hover {
				background-color: var(--stan-gray-light-color) !important;
			}
		}
	}
	.posts-list-container {
		@apply w-full;
		.empty-post {
			@apply box-border border-dashed rounded-xl border-stan-gray-dark border;
			@apply w-full p-24 flex flex-col justify-center items-center;
			@apply text-stan-text-light text-base -tracking-32 leading-6;
		}
	}
	.buttons-wrapper {
		@apply flex flex-row gap-1;
	}
	.fade-move,
	.fade-enter-active,
	.fade-leave-active {
		transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
		transform: scaleY(0.01) translate(30px, 0);
	}

	.fade-leave-active {
		position: absolute;
	}
</style>
