<template>
	<Teleport to="body">
		<AppModal ref="editLinkModal" title="Edit Link" action="Save" :loading="loading" :backdrop="backdrop" @cta="editLink" @close="close">
			<div class="profile-sub-secion-right-image">
				<div class="profile-photo-wrapper mb-3" id="pick-avatar">
					<a class="cursor-pointer profile-photo-wrapper mb-3" @click="uploadLinkImage">
						<AppUserImage color="#000000" :src="image" name="Link" class="lg rounded-full mb-5" />
						<div class="abs-add-image">
							<img src="@/assets/icons/add-image-filled.svg?url" class="img-fluid" />
						</div>
					</a>
				</div>
			</div>
			<AppInput class="mb-2" v-model="title" label="Label" placeholder="Name your link here" :error="v$.title.$errors" :maxlength="50" />
			<AppInput class="mb-0" v-model="url" label="URL" placeholder="Paste your URL here" :error="v$.url.$errors" />
			<AppUploadImage key="EditLinkModal" ref="uploadLinkImage" @uploaded="data => fileUploaded(data)" scope="user" />
		</AppModal>
	</Teleport>
</template>

<script>
	import { useVuelidate } from '@vuelidate/core'
	import { required, helpers, url, maxLength } from '@vuelidate/validators'
	import { useCommunityStore } from '@/stores/communities'
	import { autoAddHttps, isValidURL, isValidURLWithoutHttp } from '../shared/utils'

	export default {
		setup: () => ({ v$: useVuelidate() }),
		props: {
			isEditing: {
				type: Boolean,
				default: false,
			},
			backdrop: {
				type: Boolean,
				default: true,
			},
			linkIndex: {
				type: Number,
			},
			link: {
				type: Object,
			},
		},
		data() {
			return {
				title: '',
				url: '',
				image: '',
				loading: false,
			}
		},
		computed: {
			communityStore() {
				return useCommunityStore()
			},
			showing() {
				return this.$refs.editLinkModal.showing
			},
		},
		validations() {
			return {
				title: {
					required: helpers.withMessage('Title is required', required),
				},
				url: {
					urlWithoutProtocol: helpers.withMessage('Please enter a valid URL', value => {
						if (!value) return false // Ensure it's not empty
						return isValidURL(value) || isValidURLWithoutHttp(value)
					}),
					required: helpers.withMessage('URL is required', required),
				},
			}
		},
		methods: {
			uploadLinkImage() {
				this.$refs.uploadLinkImage.triggerPickfile()
			},
			fileUploaded(fileData) {
				this.image = fileData.src
				this.$refs.uploadLinkImage.hide()
			},
			async editLink() {
				const isFormCorrect = await this.v$.$validate()
				if (!isFormCorrect) {
					return
				}
				const url = autoAddHttps(this.url)
				const payload = {
					title: this.title,
					image: this.image,
					url: url,
				}
				try {
					this.loading = true
					const links = [...(this.communityStore.currentCommunity.data.links ?? [])]
					links[this.linkIndex] = payload
					this.communityStore.updateCommunityData({
						links,
					})
					this.$notify({
						type: 'Success',
						title: `Done!`,
						text: `Link edited.`,
					})

					this.close()
				} catch (error) {
					if (error.response?.data?.message) {
						this.$notify({ type: 'error', text: error.response?.data?.message })
					}
				}
				this.loading = false
			},

			show() {
				this.v$.$reset()
				this.$refs.editLinkModal.show()
				this.resetForm()
			},
			close() {
				this.$emit('close')
				this.$refs.editLinkModal.hide()
			},
			resetForm() {
				if (this.link?.title) {
					this.title = this.link.title
					this.url = this.link.url
					this.image = this.link.image ?? 'https://assets.stanwith.me/graphics/random/url-default.png'
				} else {
					this.title = null
					this.url = null
					this.image = 'https://assets.stanwith.me/graphics/random/url-default.png'
				}
			},
			productTagsUpdated(tags) {
				this.selectedProducts = tags.map(e => e.page_id)
			},
		},
	}
</script>

<style lang="scss" scoped>
	.invite-user-modal {
		:deep(.modal-inner-title) {
			overflow: visible;
		}
	}
	.profile-sub-secion-right-image {
		max-width: 100px;
		.profile-photo-wrapper {
			position: relative;
			.abs-add-image {
				position: absolute;
				right: 20px;
				bottom: -8px;
				display: inline-block;
				img {
					width: 20px;
				}
			}
		}
	}
</style>
