<template>
	<Teleport to="body">
		<AppModal
			ref="webinarSectionModal"
			title="Upcoming Webinar Sessions"
			action="Done"
			:backdrop="backdrop"
			@cta="close"
			@close="close"
			:closeOnBlur="closeOnBlur"
		>
			<div v-if="sessions.length > 0" v-for="(session, index) in sessions">
				<SessionComponent :session="session" :webinar="webinar" />
			</div>
			<div
				class="bg-white p-5 flex flex-col border-dotted border-2 border-stan-gray rounded-xl text-stan-text-light font-light text-sm items-center justify-center text-center"
				v-else
			>
				There are no upcoming slots for this webinar!
			</div>
		</AppModal>
	</Teleport>
</template>

<script>
	import SessionComponent from './SessionComponent.vue'
	import { useCommunityPublicStore } from '@/stores/public'
	import { configSettings } from '@/plugins/configSettings'

	export default {
		props: {
			isEditing: {
				type: Boolean,
				default: false,
			},
			backdrop: {
				type: Boolean,
				default: true,
			},
			closeOnBlur: {
				type: Boolean,
				default: true,
			},
			webinar: {
				type: Object,
				required: true,
			},
			sessions: {
				type: Object,
				required: true,
			},
		},
		computed: {
			communityPublicStore() {
				return useCommunityPublicStore()
			},
			showing() {
				return this.$refs.webinarSectionModal.showing
			},
		},
		methods: {
			show() {
				this.$refs.webinarSectionModal.show()
			},
			close() {
				this.$emit('close')
				this.$refs.webinarSectionModal.hide()
			},
		},
	}
</script>
